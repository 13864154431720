<script>
/**
 * name：公式测试
 * user：sa0ChunLuyu
 * date：2021-12-28 10:45:24
 */
export default {
  data() {
    return {
      posterUrl: '',
    }
  },
  mounted() {
    setTimeout(() => {
      this.$sa0.h2c('html2canvas_wrapper', url => {
        this.posterUrl = url
      })
    }, 2000)
  },
  methods: {}
}
</script>
<template>
  <div>
    <div id="html2canvas_wrapper">
      <img src="/assets/avatar.jpg" alt="">
      <div>HTML2Canvas</div>
    </div>
    <div class="img_wrapper" v-if="posterUrl">
      <img class="" :src="posterUrl" alt="">
    </div>
  </div>
</template>
<style scoped>
.img_wrapper {
  border: 1px solid #9a6e3a;
}

.img_wrapper img {
  width: 200px;
  background: #000;
}

#html2canvas_wrapper {
  width: 200px;
}

#html2canvas_wrapper img {
  width: 200px;
  height: 50px;
  border-radius: 20px;
}

#html2canvas_wrapper div {
  font-weight: bold;
}
</style>
